<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">
				<span>Amazon授权</span>
			</h3>
		</div> -->
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">Amazon<span>{{$t('i18nn_fc7197a70838eeeb')}}</span></span>
						<!-- <el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini"
								@click="exportExcelAction()"></el-button>
						</el-tooltip> -->
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <ul class="actionConList"> -->
						<!-- <li> -->
							<router-link :to="{ name: 'AmazonAuth' }" target="_blank">
								<el-button type="success" size="small" icon="el-icon-connection">Amazon授权</el-button>
							</router-link>
						<!-- </li> -->
					<!-- </ul> -->
					
					<router-link :to="{name:'amazonHelpDoc'}">
						<i class="el-icon-s-help"></i>
						<span>{{$t('i18nn_e93cde152c0070f7')}}</span>
					</router-link>
					
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>
		<!-- <div class="formMsgCon">
        <h3><i class="titleIcon el-icon-bell"></i>{{$t('i18nn_41413cddfa61538c')}}</h3>
        <div class="formMsgDet">
          <p>
            此为录入仓库的SKU数据
          </p>
        </div>
    </div> -->

		<!-- <div class="wh-filter-con"> -->
			<!-- <div class="wh-filter-item">
				<span>SKU:</span>
				<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.goodsSku" maxlength="50" :clearable="true" style="width: 250px;"></el-input>
			</div>
			<div class="wh-filter-item">
				<span>{{ $t('Storage.skuInfo.Chinese_title') }}:</span>
				<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.goodsName" maxlength="50" :clearable="true" style="width: 150px;"></el-input>
			</div>
			<div class="wh-filter-item">
				<span>{{ $t('Storage.skuInfo.English_title') }}:</span>
				<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.goodsNameEn" maxlength="50" :clearable="true" style="width: 150px;"></el-input>
			</div>
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
				<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword" maxlength="50" :clearable="true" style="width: 150px;"></el-input>
			</div> -->
			<!-- <el-button type="primary" @click="serPageData" size="small">{{ $t('FormMsg.Search') }}</el-button> -->
		<!-- </div> -->
		
		<div class="filterCon" style="">
			<div class="filterBarList">
				
			</div>
			<div class="filterAction">
				<!-- <ul class="actionConList">
					<li>
						<router-link :to="{ name: 'AmazonAuth' }" target="_blank">
							<el-button type="success" size="small" icon="el-icon-connection">Amazon授权</el-button>
						</router-link>
					</li>
				</ul> -->
				<ul class="filterConList">
					
				</ul>
			</div>
		</div>
		
		<!-- <div class="wh-action-con">
			<router-link :to="{ name: 'AmazonAuth' }" target="_blank">
				<el-button type="success" size="small" icon="el-icon-connection">Amazon授权</el-button>
			</router-link>
		</div> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small" :max-height="$store.state.frameConHeightWh">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>
							
				<!-- <el-table-column prop="cusName" :label="'cusName'"></el-table-column> -->
				<el-table-column prop="sellerName" :label="'sellerName'"></el-table-column>
				<el-table-column prop="sellingPartnerId" :label="'sellingPartnerId'"></el-table-column>
							
				<el-table-column prop="tokenType" :label="'tokenType'"></el-table-column>
				
				<el-table-column prop="userId" :label="'userId'"></el-table-column>
				<!-- openEdit(event, row, index) -->
				<el-table-column :label="$t('i18nn_93f5ca01b006206c')" width="140px" fixed="right">
				  <template slot-scope="scope">
				    <el-button @click="openEdit($event,scope.row, scope.$index)" type="primary" size="mini" icon="el-icon-edit">{{$t('i18nn_4326c21ea533d819')}}</el-button>
				  </template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>
		
		<!-- 更新别名 -->
		<el-dialog :close-on-click-modal="false"   :title="$t('i18nn_4326c21ea533d819')" append-to-body :visible.sync="dialogFormVisible"  custom-class="myFullDialog2">
		
		  
		
		  <el-form ref="form" :rules="formRules" :size="'small'" :model="form" label-width="150px" style="width: 700px;" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">
		
		    <el-form-item label="ID" prop="id">
		      <el-input size="mini" disabled v-model="form.id" style="width: 300px;"></el-input>
		    </el-form-item>
		    <el-form-item :label="$t('i18nn_efc15adf80699301')" prop="sellerName">
					<el-input type="text" v-model="form.sellerName" style="width: 300px;" :placeholder="$t('FormMsg.Please_Enter')"></el-input>
		    </el-form-item>
		
		  </el-form>
		 
		  <div slot="footer" class="dialog-footer">
		    <el-button type="primary" plain @click="dialogFormVisible = false">{{$t('FormMsg.Close')}}</el-button>
		    <el-button type="primary" icon="el-icon-tickets" @click="submitForm('form')">{{$t('FormMsg.Save')}}</el-button>
		  </div>
		</el-dialog>

		<!--查看明细-->
		<!-- <el-dialog :title="$t('Storage.skuInfo.SKU_detail')" append-to-body :close-on-click-modal="false" :visible.sync="dialogShowDet" width="1200px" top="10px">
      <div style="">
        <el-table
            :data="DetData"
            stripe
            :border="true"
            style="width: 100%"
            size="small"
          >
            <el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>

            <el-table-column prop="subjectName" :label="$t('Storage.skuInfo.category')"></el-table-column>

            <el-table-column prop="goodsSku" label="SKU">

            </el-table-column>

            <el-table-column prop="goodsName" :label="$t('Storage.skuInfo.Chinese_title')">

            </el-table-column>

            <el-table-column prop="goodsNameEn" :label="$t('Storage.skuInfo.English_title')">

            </el-table-column>

            <el-table-column prop="goodsImg" :label="$t('Storage.skuInfo.SKU_pictures')">
              <template slot-scope="scope">
                <div>
                  <el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.goodsImg" @click="openBigImg($event, scope.row.goodsImg)">
                    <div slot="error" class="image-slot" style="font-size: 40px;"><i class="el-icon-picture-outline"></i></div>
                  </el-image>
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="declarePrice" :label="$t('Storage.skuInfo.price')"></el-table-column>
            <el-table-column prop="goodsWeight" :label="$t('Storage.skuInfo.weight')"></el-table-column>

            <el-table-column prop="sizeUnitName" :label="$t('Storage.skuInfo.unit')"></el-table-column>

            <el-table-column prop="goodsLength" :label="$t('Storage.skuInfo.length')"></el-table-column>
            <el-table-column prop="goodsWidth" :label="$t('Storage.skuInfo.width')"></el-table-column>
            <el-table-column prop="goodsHeight" :label="$t('Storage.skuInfo.height')"></el-table-column>
            <el-table-column prop="safeStock" :label="$t('Storage.skuInfo.safety_stock')"></el-table-column>

            <el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')"></el-table-column>

          </el-table>
      </div>
      <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogShowDet = false">{{$t('FormMsg.Close')}}</el-button></div>
    </el-dialog> -->

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="1000px" top="0">
      <div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div>
      <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogImgVisible = false">{{$t('FormMsg.Close')}}</el-button></div>
    </el-dialog> -->
	</div>
</template>
<script>
// import excelUtilsNew from '@/utils/excelUtilsNew.js';
// import SelAgentUser from '@/components/Common/SelAgentUser.vue';
export default {
	// name: 'BuyerOrder',
	//meta信息seo用
	// metaInfo: {
	//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
	//   meta: [{ // set meta
	//     name: '互易天下-买家中心-收货账单',
	//     content: '互易天下-厂家共享平台-买家中心-收货账单'
	//   }],
	//   // link: [{                 // set link
	//   //   rel: 'asstes',
	//   //   href: 'https://assets-cdn.github.com/'
	//   // }]
	// },
	// props: ['isDialog'],
	// props:{
	//   // mobile:"",
	//   isSel:{
	//     default: function() {
	//       return false
	//     },
	//     type: Boolean
	//   },
	//   status:{
	//     default: function() {
	//       return ''
	//     },
	//     type: String
	//   },
	// },
	components: {
		// SelAgentUser
	},
	data() {
		return {
			// activeName: 'second',
			// isShowFrom:false,
			// pickerOptions1: {
			//     disabledDate(time) {
			//       return time.getTime() > Date.now();
			//     },
			//     shortcuts: [{
			//       text: this.$t('i18nn_0200bd47bb4bb83d'),
			//       onClick(picker) {
			//         picker.$emit('pick', new Date());
			//       }
			//     }, {
			//       text: this.$t('i18nn_84b73bfc6dada445'),
			//       onClick(picker) {
			//         const date = new Date();
			//         date.setTime(date.getTime() - 3600 * 1000 * 24);
			//         picker.$emit('pick', date);
			//       }
			//     }, {
			//       text: this.$t('i18nn_e3f3138cf5ba3f6d'),
			//       onClick(picker) {
			//         const date = new Date();
			//         date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
			//         picker.$emit('pick', date);
			//       }
			//     }]
			//   },
			// dialogShowDet: false,
			// DetData:[],

			// UserInfo: this.$store.getters.getUserInfo,
			dialogFormVisible: false,
			// dialogFormStatus: 0, //0-新增，1-修改

			//图片放大
			// dialogImgVisible: false,
			// imgUrlBigShow: '',

			loading: false,
			
			form: {
			    "id":null,//"数据ID",
					"sellerName":'',//"店铺ABC"
			},

			formRules: {
			//   goodsSubjectId: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			},
			loading_load: false,
			tableData: [],
			//分页数据
			pagination: this.$Utils.defaultPagination(),
			// selectOption: {
			//   wh_weight_unit: [],
			//   wh_vol_unit: [],
			//   wh_goods_fee_type:[],
			//   // statusList: [
			//   //   {
			//   //     value: '',
			//   //     label: this.$t('i18nn_16853bda54120bf1')
			//   //   },
			//   //   {
			//   //     value: '1',
			//   //     label: this.$t('i18nn_fdd8426d7b8869c5')
			//   //   },
			//   //   {
			//   //     value: '2',
			//   //     label: this.$t('i18nn_619d7e563f879811')
			//   //   },
			//   //   {
			//   //     value: '3',
			//   //     label: this.$t('i18nn_1dfb3a065d3ffe39')
			//   //   }
			//   // ]
			// },
			//查询，排序方式
			filterData: {
				orderBy: 'id_', //排序字段
				sortAsc: 'desc' //desc降序，asc升序
				// "accountPeriod":"",
				// "billNo":""
				// status: '',
				// putWhNo: '',
				// agentUser:"",
				//     goodsSku: '',
				//     // hashCode: '',
				//     goodsName: '',
				//     goodsNameEn: '',
				//     keyword:""
				// declareNameCh: '',
			}
		};
	},

	//创建时
	created() {
		// this.getPageData();
	},
	//编译挂载前
	mounted() {
		// this.getDicData();

		this.initData();
	},
	methods: {
		initData() {
			this.pagination.current_page = 1;
			// this.currentSel = {};
			// this.multipleSelection = [];
			this.getPageData();
			// this.getDicData();
		},
		//   showDetList($event,row){
		//     $event.stopPropagation();
		//     this.dialogShowDet = true;
		//     this.DetData = [row.mySku];
		//   },
		// changeAgentUser(val){
		// 	this.filterData.agentUser = val;
		// 	this.initData();
		// },

		//导出 excel
		// exportExcel() {

		// 	let columns = [
		// 		{
		// 			title: this.$t('i18nn_29991afa9781d554'),
		// 			key: 'cusName'
		// 		},
		//     {
		//     	title: this.$t('Storage.skuInfo.SKU_title'),
		//     	key: 'goodsName',
		// 			types: 'custom',
		// 			formatter:function(row){
		// 				return row.mySku.goodsName+row.mySku.goodsNameEn
		// 			}
		//     },
		// 		{
		// 			title: 'SKU',
		// 			key: 'goodsSku'
		// 		},
		//     {
		//     	title: this.$t('Storage.skuInfo.warehouse'),
		//     	key: 'whNo'
		//     },
		//     {
		//     	title: this.$t('Storage.skuInfo.on_hand_inventory'),
		//     	key: 'totalStock'
		//     },
		//     {
		//     	title: this.$t('Storage.skuInfo.Current_locked_inventory'),
		//     	key: 'lockStock'
		//     },
		//     {
		//     	title: this.$t('Storage.skuInfo.Current_available_stock'),
		//     	key: 'currentStock'
		//     },
		//     {
		//     	title: this.$t('Storage.skuInfo.unit'),
		// 			key: 'sizeUnitName',
		// 			types: 'custom',
		// 			formatter:function(row){
		// 				return row.mySku.sizeUnitName
		// 			}
		//     },
		// 		{
		// 			title: this.$t('Storage.skuInfo.unit_price'),
		// 			key: 'declarePrice',
		// 			types: 'custom',
		// 			formatter:function(row){
		// 				return row.mySku.declarePrice
		// 			}
		// 		},
		// 		{
		// 			title: 'SKU '+this.$t('Storage.skuInfo.length')+'*'+this.$t('Storage.skuInfo.width')+'*'+this.$t('Storage.skuInfo.height'),
		// 			// key: '{{scope.row.mySku.goodsLength}}*{{scope.row.mySku.goodsWidth}}*{{scope.row.mySku.goodsHeight}}'
		// 			key: 'lwh',
		// 			types: 'custom',
		// 			formatter:function(row){
		// 				return row.mySku.goodsLength+'*'+row.mySku.goodsWidth+'*'+row.mySku.goodsHeight
		// 			}
		// 		},
		// 		{
		// 			title: 'SKU '+this.$t('Storage.skuInfo.weight'),
		// 			// key: 'mySku.goodsWeight',
		// 			key: 'goodsWeight',
		// 			types: 'custom',
		// 			formatter:function(row){
		// 				return row.mySku.goodsWeight
		// 			}
		// 		},

		// 		{
		// 			title: this.$t('Storage.tableColumn.remark'),
		// 			key: 'remark'
		// 		},
		// 	];
		// 	let Data = this.tableData;

		// 	excelUtilsNew.exportExcel(columns, Data, 'WSkuInventories');
		// },
		//跳转页面
		// toPageUrl(name){
		//   this.$router.push({'name':name});
		// },
		//打开新增编辑，弹窗
		openDioalog(formParm) {
		  console.log(formParm);
		  this.dialogFormVisible = true;
			
			this.form.id = formParm.id;
			this.form.sellerName = formParm.sellerName;
		  // let form = Object.assign({}, formParm);
		//   console.log('form',form);
		//   // // 重置
		  // this.resetForm('form');
		//   if (null === formParm) { //新增

		//     this.form.weightUnit = '1';
		//     this.form.volumeUnit = '1';
		//     this.form.whFeeType = '1';

		//     this.dialogFormStatus = 0;
		//     // form.subUserId = null;
		//     // form.userSubUserId = null;
		//     // form.state = true;
		//     //浅拷贝、对象属性的合并
		//     this.form = form;

		//   } else { //修改
		//     this.dialogFormStatus = 1;
		//     // form.state = form.state === '0' ? true : false;

		//     //浅拷贝、对象属性的合并
		//     this.form = form;

		//   }
		//   // this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
		//   //       type: 'warning',
		//   //       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//   //     });
		},
		//打开编辑
		openEdit(event, row, index) {
		  event.stopPropagation();
		  this.openDioalog(row);
		},

		//删除
		// delAction(event, row) {
		//   this.$confirm(this.$t("FormMsg.confirm_Delete"), this.$t('tips.tipsTitle'), {
		//     // confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//     // cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
		//     type: 'warning'
		//   })
		//     .then(() => {
		//       // this.$message({
		//       //   type: 'success',
		//       //   message: '删除成功!'
		//       // });
		//       this.delDataAction(event, row);
		//     })
		//     .catch(() => {
		//       // this.$message({
		//       //   type: 'info',
		//       //   message: this.$t('i18nn_2e58cb9b52e2a214')
		//       // });
		//     });
		// },
		// //删除
		// delDataAction(event, row) {
		//   event.stopPropagation();
		//   console.log('delDataAction', row);
		//   // let parm = [];

		// //   if (!!row) {
		// //     //单条
		// //     parm = [row.id];
		// //   } else {
		// //     //多条
		// // //     let dataList = this.multipleSelection;
		// // //     if (dataList.length < 1) {
		// // //       this.$message.warning(this.$t('i18nn_7b80e66b535a3732'));
		// // //       return;
		// // //     }

		// // //     let dataListParm = dataList.map(v => v.id);
		// // //     parm = dataListParm;
		// //     // console.log('dataListParm', dataListParm);
		// //     // let dataParm = {
		// //     //   ids: dataListParm
		// //     // };
		// //   }

		//   this.postData(this.$urlConfig.WhMyGoodsSkuDel+'/'+row.id, {},'delete');
		// },

		// hyUpLoadImg1: function(childValue) {
		//   // childValue就是子组件传过来的值
		//   this.form.goodsImg = childValue;
		// },

		//查看图片
		// openBigImg(event, imgsrc) {
		//   event.stopPropagation();
		//   console.log('openBigImg', imgsrc);
		//   this.dialogImgVisible = true;
		//   this.imgUrlBigShow = imgsrc;
		// },

		//点击状态为非出账弹出窗
		// noConfirm(){
		//   this.$alert(this.$t('i18nn_e741d17b1fd891c2')已出账this.$t('i18nn_d6f109b7bee2f33f')确认"操作，请核实！', this.$t('tips.tipsTitle'), {
		//         type: 'warning',
		//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//       });
		// },
		//提交信息
		submitForm(formName) {

		  this.$refs[formName].validate((valid) => {

		    if (valid) {
		      let formData = Object.assign({}, this.form);
		      //浅拷贝、对象属性的合并
		      // if (0 === this.dialogFormStatus) {

		      //   formData.id = null;
		      //   formData.userId = this.UserInfo.id;

		      //   this.postData(this.$urlConfig.WhMyGoodsSkuUpdate, formData);

		      // } else {

		      //   formData.userId = this.UserInfo.id;
		        this.postData(this.$urlConfig.amazonAuthUpdateName, formData);
		      // }

		    } else {
		      console.log('error submit!!');
		      this.$alert(this.$t("tips.checkSubmitData"), this.$t('tips.tipsTitle'), {
		        type: 'warning',
		        //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		      });
		      return false;
		    }
		  });
		},
		// //重置输入框
		// resetForm(formName) {
		//   console.log(formName);
		//   // console.log(this.$refs[formName]);

		//   if (this.$refs[formName]) {
		//     this.$refs[formName].resetFields();

		//   } else {
		//     console.log('this.$refs[formName]',this.$refs[formName]);
		//   }
		// },

		//提交信息
		postData(url, formData,type) {
		  // let _this = this;
		  this.loading = true;
		  let HttpType = {};
		  if('delete'==type){
		    HttpType = this.$http.delete(url, formData);
		  } else {
		    HttpType = this.$http.put(url, formData);
		  }
		  // formData.state = formData.state ? '0' : '1';
		  HttpType
		    .then(({ data }) => {
		      console.log(this.$t('tips.submitSuccess'));
		      console.log(data);
		      
		      this.loading = false;
		      if (200 == data.code) {
		        this.dialogFormVisible = false;
		        this.getPageData();
		        this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
		          type: 'success',
		          //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		        });
		      } else {
		        // if (!data.msg) {
		        //   data.msg = this.$t('tips.submitError');
		        // }
		        this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
		          type: 'warning',
		          //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		        });
		      }
		    })
		    .catch((error) => {
		      console.log(error);
		      console.log(this.$t("tips.submitError"));
		      this.loading = false;
		      this.$alert(this.$t("tips.submitRequestError"), this.$t('tips.tipsTitle'), {
		        type: 'warning',
		        //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		      });
		    });
		},
		//查询数据
		serPageData() {
			this.pagination.current_page = 1;
			this.getPageData();
		},
		//请求分页数据
		getPageData() {
			// let _this = this;
			this.loading_load = true;
			this.$http
				.put(this.$urlConfig.amazonAuthPageList, {
					sortAsc: this.filterData.sortAsc,
					orderBy: this.filterData.orderBy,
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size //当前页显示数目

					// proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					//      // status: this.filterData.status ? this.filterData.status : null,
					//      // putWhNo: this.filterData.putWhNo ? this.filterData.putWhNo : null,
					//      goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
					//      // hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
					//      goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
					//      goodsNameEn: this.filterData.goodsNameEn ? this.filterData.goodsNameEn : null,
					//      keyword: this.filterData.keyword ? this.filterData.keyword : null,
					//      // declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
				})
				.then(({ data }) => {
					console.log(this.$store.getters.getUserInfo);
					console.log('分页，请求成功');
					console.log(data);
					
					this.loading_load = false;
					//表格显示数据
					this.tableData = data.rows;
					//当前数据总条数
					this.pagination.total = parseInt(data.total);
					//当前页数
					// this.pagination.current_page = parseInt(data.current);
					//当前页条数
					// this.pagination.page_size = parseInt(data.size);
				})
				.catch(error => {
					console.log(error);
					console.log('分页，请求失败');
					this.loading_load = false;
				});
		},
		//操作
		// sureBillAction(row,msg,state) {
		//   console.log(this.$t('i18nn_4f5bb4ff8b3d804b'), row);
		//   console.log(row);
		//   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
		//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//     //cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
		//     type: 'warning'
		//   }).then(() => {
		//     //确认
		//     let dataParm = {
		//       ids:[row.id],
		//       state:state,
		//     }
		//     this.updatePageData(dataParm, msg);
		//   }).catch(() => {

		//   });

		// },
		//拉取订单
		// pullOrderAction() {
		// 	this.loading_load = true;
		// 	//   //浅拷贝、对象属性的合并
		// 	this.$http
		// 		.post(this.$urlConfig.AmazonOrderPull, {
		// 			spSellerId: 'A1UIXJVRE74PV1',
		// 			lastUpdatedAfter: '2021-07-16 13:00:00'
		// 		})
		// 		.then(({ data }) => {
		// 			this.loading_load = false;
		// 			if (200 == data.code) {
		// 				this.$message.success('拉取订单成功！');
		// 				// this.getPageData();
		// 			} else {
		// 				this.$message.warning(data.msg ? data.msg : '拉取订单失败,请重试');
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('更新特定数据，请求失败');
		// 			this.loading_load = false;
		// 			this.$message.warning('拉取订单失败,请重试！');
		// 		});
		// }
		//更新特定数据,
		// updatePageData(dataParm, msg) {
		//   // let _this = this;
		//   this.loading_load = true;
		//   //浅拷贝、对象属性的合并
		//   let dataParmAss = Object.assign({}, dataParm);
		//   // dataParmAss.isDefault = 1;
		//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
		//     .then(({ data }) => {
		//       console.log(msg + "特定数据，请求成功");
		//       console.log(data);
		//       if (200 == data.code) {
		//         this.$message.success(msg + '成功！');
		//         this.getPageData();
		//       } else {
		//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
		//       }
		//     })
		//     .catch((error) => {
		//       console.log(error);
		//       console.log("更新特定数据，请求失败");
		//       this.loading_load = false;
		//       this.$message.warning('' + msg + '失败,请重试！');
		//     });
		// },
		//选择数据后回调
		// selRow(event,row) {
		//   event.stopPropagation();
		//   this.$emit('selectRow',row)
		// },
		//查询数据字典
		// getDicData() {
		//   // let _this = this;
		//   // console.log(keyword);

		//   // this.loading_load = true;
		//   this.$http
		//     .put(this.$urlConfig.HyDicQueryList, ['wh_weight_unit', 'wh_vol_unit','wh_goods_fee_type'])
		//     .then(({ data }) => {
		//       console.log('查询数据字典，请求成功');
		//       console.log(data);
		//       if (200 == data.code && data.data) {
		//         this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
		//         this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
		//         this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
		//       } else {
		//         if (!data.msg) {
		//           data.msg = this.$t("tips.submitError");
		//         }
		//         this.$message.warning(data.msg);
		//       }
		//     })
		//     .catch(error => {
		//       console.log(error);
		//       console.log('查询数据字典接口，请求失败');
		//       this.$message.error(this.$t("tips.submitRequestError"));
		//     });
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
// /deep/ .el-input-number,
//   .el-select {
//     // width: 100px;
//     .el-input__inner {
//       text-align: left;
//     }
//   }
// .form_msg {
//   color: #e6a23c;
// }
</style>
